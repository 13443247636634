import React from "react";
import "./style.scss";

const Preloader = () => {
  return (
    <div class="article">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Preloader;
