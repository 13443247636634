import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import PreLoader from "../../preloader/preloader";
import "./style.scss";
import { FaShoppingCart } from "react-icons/fa";

const Blocks = ({
  id,
  postImg,
  title,
  description,
  index,
  store,
  domain,
  cta_button_type,
  sides,
}) => {
  let domain_url = domain?.domain_url;
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  let pros = [];
  let cons = [];

  sides?.forEach((element) => {
    if (element.is_good) {
      pros.push(element);
    } else {
      cons.push(element);
    }
  });

  return (
    <>
      <div className="post-details">
        <h1 className="title">
          {index + 1}. {title}
        </h1>
        {postImg && (
          <img
            class="in-content"
            src={addStr(postImg, 65, "800_")}
            srcset={`${addStr(postImg, 65, "480_")} 480w, ${addStr(postImg, 65, "800_")} 800w`}
            sizes="(max-width: 600px) 480px, 800px"
            alt={title}
          />
        )}
        {store ? (
          <>
            <div className="main-content">
              {ReactHtmlParser(
                description.replaceAll(
                  "[store-link]",
                  `https://go.${domain_url}/?store_id=${store[0]?.id}&product_id=${id}&domain_id=${domain?.id}`
                )
              )}
              {pros && (
                <>
                  {pros?.length > 0 && (
                    <>
                      <h2>Pros</h2>
                      <ol>
                        {pros?.map((pro) => {
                          return (
                            <li data-list="bullet">
                              <span className="ql-ui" />
                              {pro.body}
                            </li>
                          );
                        })}
                      </ol>
                    </>
                  )}
                </>
              )}
              {cons && (
                <>
                  {cons?.length > 0 && (
                    <>
                      <h2>Cons</h2>
                      <ol>
                        {cons?.map((con) => {
                          return (
                            <li data-list="bullet">
                              <span className="ql-ui" />
                              {con.body}
                            </li>
                          );
                        })}
                      </ol>
                    </>
                  )}
                </>
              )}
            </div>
            {domain && (
              <p class="buttons">
                <a
                  class="button p-button"
                  href={`https://go.${domain_url}/?store_id=${store[0]?.id}&product_id=${id}&domain_id=${domain?.id}`}
                  target="_blank"
                >
                  {cta_button_type == 1 ? (
                    <span>Purchase From {store[0]?.name}</span>
                  ) : cta_button_type == 2 ? (
                    <span>
                      Shop Now
                      <FaShoppingCart
                        style={{ " margin-inline-start": "5px", "padding-top": "5px" }}
                      />
                    </span>
                  ) : cta_button_type == 3 ? (
                    <span>Get Started</span>
                  ) : (
                    <span>BOOK NOW</span>
                  )}
                </a>
              </p>
            )}
          </>
        ) : (
          <div className="main-content">{ReactHtmlParser(description.join(""))}</div>
        )}
      </div>
    </>
  );
};

const isBrowser = typeof window !== "undefined";

const Content = ({ domain }) => {
  let domain_url = domain?.domain_url;

  let generateJsonLink = () => {
    if (isBrowser) {
      let url = window.location.href;
      let slug = url.split("/")[3];
      let jsonLink = `https://idc-ads-media-production.s3.ap-south-1.amazonaws.com/${domain_url}/${slug}.json`;
      return jsonLink;
    }
  };

  const [contentData, setContentData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  let page_data = generateJsonLink();

  useEffect(() => {
    axios.get(page_data).then((response) => {
      setContentData(response.data);
      setLoading(false);
    });
  }, [isBrowser, page_data]);
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  const replace_array = [
    { replace_name: "[domain-name]", replace_valve: domain?.name },
    { replace_name: "[domain-url]", replace_valve: domain_url },
  ];
  let stores = contentData?.stores;
  let awic_rule = contentData?.awic_rule;

  stores?.forEach((iterator, i) => {
    let obj = {};
    if (i == 0) {
      if (!awic_rule) {
        obj.replace_name = "[store-link]";
        obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}`;
        replace_array.push(obj);
      } else {
        if (awic_rule?.country?.id) {
          obj.replace_name = "[store-link]";
          obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}&network_account_id=${awic_rule?.awicNetworkAccountId}&country_id=${awic_rule?.countryId}`;
          replace_array.push(obj);
        } else {
          obj.replace_name = "[store-link]";
          obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}&network_account_id=${awic_rule?.awicNetworkAccountId}`;
          replace_array.push(obj);
        }
      }
    } else {
      if (!awic_rule) {
        obj.replace_name = `[store-link-${i}]`;
        obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}`;
        replace_array.push(obj);
      } else {
        obj.replace_name = `[store-link-${i}]`;
        obj.replace_valve = `https://go.${domain_url}/?store_id=${iterator?.id}&domain_id=${domain?.id}&network_account_id=${awic_rule?.awic_network_account.id}&country_id=${awic_rule?.country?.id}`;
        replace_array.push(obj);
      }
    }
  });

  let render_item = contentData?.content ? contentData?.content : "";
  if (render_item) {
    render_item = render_item.join("");
  }
  replace_array?.forEach(({ replace_name, replace_valve }) => {
    render_item = render_item.replaceAll(replace_name, replace_valve);
  });

  let blocks = [];
  let products = [];

  if (contentData?.hasOwnProperty("blocks")) {
    blocks = contentData?.blocks;
  }

  if (contentData?.hasOwnProperty("products")) {
    products = contentData?.products;
  }

  return isLoading ? (
    <>
      <PreLoader />
    </>
  ) : (
    <div className="article">
      {contentData?.image && (
        <figure className="image is-5by3">
          <img
            src={addStr(contentData?.image, 65, "800_")}
            srcset={`${addStr(contentData?.image, 65, "480_")} 480w, ${addStr(
              contentData?.image,
              65,
              "800_"
            )} 800w`}
            sizes="(max-width: 600px) 480px, 800px"
            alt={contentData?.title}
          />
        </figure>
      )}
      <div className="article-title">
        <h1>{contentData?.title}</h1>
      </div>
      {contentData?.categories?.length > 0 && (
        <div className="article-category">
          <span>{contentData?.categories[0]?.name}</span>
        </div>
      )}
      <div className="render-content">
        {contentData?.content && ReactHtmlParser(render_item)}
        {products && products?.length > 0 && ReactHtmlParser(contentData?.description?.join(""))}
        {blocks && blocks?.length > 0 && ReactHtmlParser(contentData?.description?.join(""))}
        {blocks &&
          blocks?.length > 0 &&
          blocks?.map((block, index) => {
            return (
              <Blocks
                id={block.id}
                postImg={block.image}
                title={block.title}
                key={block.id}
                description={block.description}
                className="-blocks"
                count={block.count}
                index={index}
                domain={domain}
                cta_button_type={contentData.cta_button_type}
              />
            );
          })}
        {products &&
          products?.length > 0 &&
          products?.map((product, index) => {
            return (
              <Blocks
                id={product.id}
                postImg={product.image}
                title={product.name}
                key={product.id}
                description={product.description}
                index={index}
                store={product.stores}
                sides={product.sides}
                domain={domain}
                cta_button_type={contentData.cta_button_type}
              />
            );
          })}
        {products?.length > 0 && ReactHtmlParser(contentData?.bottom_line)}
      </div>
    </div>
  );
};
export default Content;
